import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-conciliation-board',
    templateUrl: './conciliation-board.component.html',
    standalone: false
})
export class ConciliationBoardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
