import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {BackendService} from '@app/core';
import {PaymentPlan} from '@app/util/model/payment-plan';
import {Terms} from '@app/util/model/terms';
import {environment} from "@env/environment";

@Component({
    templateUrl: './example-calculator.component.html',
    styleUrls: ['./example-calculator.component.css'],
    standalone: false
})
export class ExampleCalculatorComponent implements OnInit, OnDestroy {

  annuities: PaymentPlan[];

  terms: Terms;

  amount = 5000;

  inputToggled: boolean;

  loading = true;
  amountForm: UntypedFormGroup;

  apiKey: string;

  readonly brandName = environment.brandName;


  constructor(private route: ActivatedRoute,
              private backendService: BackendService) {
    document.body.classList.add('bg-transparent');

    this.route.paramMap.subscribe(next => {
      this.apiKey = next.get('apiKey');
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.refreshAnnuities();
  }

  closeFrame() {
    window.parent.postMessage({
      'function': 'fabCloseCalculator'
    }, '*');
    window.dispatchEvent(new Event('wizardHidden'));
  }

  ngOnDestroy(): void {
    document.body.classList.remove('bg-transparent');
  }

  closeOverlay(event: any) {
    if (event.target && event.target.classList.contains('c2-overlay')) {
      this.closeFrame();
    }
  }

  private initForm() {
    this.amountForm = new UntypedFormGroup({
      amountControl: new UntypedFormControl(this.amount,
          [Validators.required, Validators.min(0)])
    });
  }

  refreshAnnuities() {
    this.loading = true;
    this.backendService.getAnnuitiesForAmount(this.amount, this.apiKey).subscribe(next => {
      if (!this.terms) {
        this.terms = next.terms;
      }
      this.amountForm.controls['amountControl'].setValidators(
          [Validators.max(this.terms.limit), Validators.min(this.terms.minPurchaseAmount)]);

      this.annuities = next.annuities;
      this.loading = false;
    });
  }

  submit() {
    this.amount = this.amountForm.controls['amountControl'].value;
    this.refreshAnnuities();
  }

}
