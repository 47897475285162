<main class="pb-0 pb-sm-5">
  <div class="container">
    <div class="row mt-3 mt-md-5 text-center">
      <div class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
        <div class="mt-3 mt-md-5 text-center">
          <div class="logo" title="Logo {{brandName}}"></div>
          <div *ngIf="loading">
            <br>
            <hr aria-hidden="true">
            <br>
            <p class="text-uppercase">Volkswagen Bank GmbH</p>
            <small class="text-uppercase">The Key To Mobility</small>
          </div>
        </div>

        <div class="mt-5" *ngIf="loading">
          <img class="img-responsive  rotate-center" src="assets/icons/spinner.svg" width="80"
               alt="" aria-hidden="true">
          <h1 class="mt-5">Ihr Einkauf wird geladen ...</h1>
        </div>

        <div class="mt-3" *ngIf="!loading && !hasError">
          <div class="text-center" [ngSwitch]="basePurchaseInfo.purchaseState"
               *ngIf="!basePurchaseInfo.pendingPurchase">
            <div *ngSwitchCase="'PENDING'">
              <i class="material-icons text-primary md-48" aria-hidden="true">videocam</i>
              <h1 class="text-uppercase py-3">Einloggen und abschließen</h1>
              <p>
                Loggen Sie sich ein um Ihren Einkauf mit {{ brandName }} abzuschliessen.
              </p>
            </div>
            <div *ngSwitchCase="'VERIFY'">
              <i class="material-icons text-primary md-48" aria-hidden="true">videocam</i>
              <h1 class="text-uppercase py-3">Einloggen und abschließen</h1>
              <p>
                Loggen Sie sich ein um Ihren Einkauf mit {{ brandName }} abzuschliessen.
              </p>
            </div>
            <div *ngSwitchCase="'PENDING_FULFILLMENT'">
              <i class="material-icons text-primary md-48" aria-hidden="true">exit_to_app</i>
              <h1 class="text-uppercase py-3">Sie können Ihren Einkauf abholen</h1>
              <p>
                Sie haben Ihren Vertrag unterschrieben. Wir haben Ihnen eine SMS mit Ihrem Abholcode gesendet.
              </p>
            </div>
            <div *ngSwitchCase="'PAID'">
              <i class="material-icons text-primary md-48" aria-hidden="true">check</i>
              <h1 class="text-uppercase py-3">Einkauf bezahlt</h1>
              <p>
                Sie haben Ihren Einkauf mit {{ brandName }} bezahlt. Der Händler wurde darüber bereits informiert.
              </p>
            </div>
            <div *ngSwitchCase="'PICKED_UP'">
              <i class="material-icons text-primary md-48" aria-hidden="true">check</i>
              <h1 class="text-uppercase py-3">Einkauf bezahlt und abgeholt</h1>
              <p>
                Sie haben einen Einkauf mit {{ brandName }} bezahlt und abgeholt. Weitere Informationen erhalten Sie im
                {{ brandName }}
                Portal.
              </p>
            </div>
            <div *ngSwitchCase="'CANCELLED'">
              <i class="material-icons text-warning md-48" aria-hidden="true">warning</i>
              <h1 class="text-uppercase py-3">Einkauf ungültig</h1>
              <p>
                Sie können diesen Einkauf nicht mehr abschließen.
              </p>
            </div>
            <div *ngSwitchCase="'TIMEOUT'">
              <i class="material-icons text-warning md-48" aria-hidden="true">warning</i>
              <h1 class="text-uppercase py-3">Einkauf ungültig</h1>
              <p>
                Sie können diesen Einkauf nicht mehr abschließen.
              </p>
            </div>
          </div>
          <div *ngIf="basePurchaseInfo.pendingPurchase">
            <i class="material-icons text-primary md-48" aria-hidden="true">shopping_cart</i>
            <h1 class="text-uppercase py-3">Sie haben bereits einen Einkauf</h1>
            <p>
              Sie können mit {{ brandName }} nur einen Einkauf auf Raten bezahlen. Loggen Sie sich ein und wählen Sie
              einen Einkauf aus, den Sie bezahlen möchten.
            </p>
          </div>

          <button mat-raised-button color="accent" (click)="goToLogin()" class="mt-5 px-5" *ngIf="showLoginLink()"
          >
            Login
          </button>
        </div>
        <div class="mt-3" *ngIf="hasError">
          <img class="img-responsive c2-anim-loader" src="assets/icons/nok.svg" width="80" aria-hidden="true" alt="">
          <div
              *ngIf="resultCode && (resultCode === 400 || resultCode === 100 || resultCode === 501 || resultCode === 230 || resultCode === 900)">
            <h1 class="mt-5">Ein Fehler ist aufgetreten</h1>
            <p>Es konnte leider kein Einkauf gefunden werden.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

