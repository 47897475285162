import * as i0 from '@angular/core';
import { Injectable, Inject, RendererFactory2 } from '@angular/core';
import * as i1 from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { fromEventPattern } from 'rxjs';
import { pluck } from 'rxjs/operators';

/**
 * @fileoverview added by tsickle
 * Generated from: lib/angular-favicon.service.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/**
 * A service to set the favicon.
 */
class AngularFaviconService {
  /**
   * @param {?} _doc
   * @param {?} rendererFactory
   */
  constructor(_doc, rendererFactory) {
    this._doc = _doc;
    this.rendererFactory = rendererFactory;
    this.darkScheme = '(prefers-color-scheme:dark)';
    this.prefersColorScheme$ = fromEventPattern(
    /**
    * @param {?} handler
    * @return {?}
    */
    handler => window.matchMedia(this.darkScheme).addListener(/** @type {?} */handler)).pipe(pluck('matches'));
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  /**
   * Get the favicon of the current HTML document.
   * @return {?}
   */
  getFavicon() {
    return this._doc.querySelector("link[rel*='icon']");
  }
  /**
   * Set the title of the current HTML document.
   * @param {?} iconURL - Default favicon URL
   * @param {?=} altIconURL - Optional, dark theme favicon URL
   * @return {?}
   */
  setFavicon(iconURL, altIconURL) {
    /** @type {?} */
    const link = this.getFavicon() || this.renderer.createElement('link');
    /** @type {?} */
    let currentLinkHref = iconURL;
    if (altIconURL) {
      this.subscribeToChangesInTheme(link, iconURL, altIconURL);
      if (window.matchMedia(this.darkScheme).matches) {
        currentLinkHref = altIconURL;
      }
    }
    this.appendLinkTag(link, currentLinkHref);
  }
  /**
   * Subscribe to the theme color changes in browser/OS and apply the appropiate favicon
   * @private
   * @param {?} link - DOM element
   * @param {?} iconURL - Default favicon URL
   * @param {?} altIconURL - Optional, dark theme favicon URL
   * @return {?}
   */
  subscribeToChangesInTheme(link, iconURL, altIconURL) {
    this.subscriptionToColorScheme = this.prefersColorScheme$.subscribe(
    /**
    * @param {?} isDarkTheme
    * @return {?}
    */
    isDarkTheme => {
      if (isDarkTheme) {
        this.appendLinkTag(link, altIconURL);
      } else {
        this.appendLinkTag(link, iconURL);
      }
    });
  }
  /**
   * Append new link to HEAD
   * @private
   * @param {?} link - DOM element
   * @param {?} iconURL - favicon URL
   * @return {?}
   */
  appendLinkTag(link, iconURL) {
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = iconURL;
    /** @type {?} */
    const head = this._doc.getElementsByTagName('head')[0];
    this.renderer.appendChild(head, link);
  }
  /**
   * @return {?}
   */
  ngOnDestroy() {
    if (this.subscriptionToColorScheme) {
      this.subscriptionToColorScheme.unsubscribe();
    }
  }
}
AngularFaviconService.decorators = [{
  type: Injectable,
  args: [{
    providedIn: 'root'
  }]
}];
/** @nocollapse */
AngularFaviconService.ctorParameters = () => [{
  type: undefined,
  decorators: [{
    type: Inject,
    args: [DOCUMENT]
  }]
}, {
  type: RendererFactory2
}];
/** @nocollapse */
AngularFaviconService.ɵprov = i0.ɵɵdefineInjectable({
  factory: function AngularFaviconService_Factory() {
    return new AngularFaviconService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i0.RendererFactory2));
  },
  token: AngularFaviconService,
  providedIn: "root"
});
if (false) {
  /**
   * @type {?}
   * @private
   */
  AngularFaviconService.prototype.renderer;
  /**
   * @type {?}
   * @private
   */
  AngularFaviconService.prototype.darkScheme;
  /**
   * @type {?}
   * @private
   */
  AngularFaviconService.prototype.subscriptionToColorScheme;
  /**
   * @type {?}
   * @private
   */
  AngularFaviconService.prototype.prefersColorScheme$;
  /**
   * @type {?}
   * @private
   */
  AngularFaviconService.prototype._doc;
  /**
   * @type {?}
   * @private
   */
  AngularFaviconService.prototype.rendererFactory;
}

/**
 * @fileoverview added by tsickle
 * Generated from: public-api.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * Generated from: angular-favicon.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

export { AngularFaviconService };
