<app-page-wrapper>
  <div class="row mt-5">
    <div class="col-12 col-md-8 offset-md-2">
      <div class="card shadow-sm">
        <div class="card-body">
          <h1>Beschwerdebearbeitung</h1>
          <h2>Wie ist eine Beschwerde einzureichen?</h2>
          <p>
            Eine Beschwerde können Sie persönlich in unseren Filialen oder auch bei unserem Kundenservice telefonisch
            unter 0531 212-0 oder auch per E-Mail <a href="mailto:info@vwfs.com">info&#64;vwfs.com</a> oder auch
            brieflich bei der Volkswagen Bank GmbH,
            Gifhorner Str. 57, 38112 Braunschweig einreichen.
          </p>
          <p>
            Um eine zeitnahe Prüfung und Beantwortung Ihrer Beschwerde sicherzustellen, sollten Sie den
            zugrundeliegenden Sachverhalt (u. a. Konto, Produkt oder Leistung, auf die sich Ihre Beschwerde bezieht)
            und
            Ihr Anliegen möglichst konkret schildern. Ferner sollten Sie uns für etwaige Rückfragen Ihre aktuellen
            Kontaktdaten (Anschrift, Telefonnummer und ggf. E-Mail-Adresse) mitteilen, sofern uns diese nicht bereits
            vorliegen.
          </p>
          <h2>Wie läuft das Beschwerdeverfahren ab?</h2>
          <p>
            Ihre Beschwerde werden wir zeitnah bearbeiten und unvoreingenommen prüfen. Soweit erforderlich, werden wir
            alle relevanten Beweismittel und Informationen zusammentragen und prüfen.
          </p>
          <p>
            Ihre Beschwerde werden wir in der Regel innerhalb von etwa 15 Arbeitstagen nach Eingang Ihrer Beschwerde
            beantworten. Beschwerden in Bezug auf Ihre Rechte und Pflichten als Zahlungsdienstnutzer nach den §§ 675c
            bis 676c des Bürgerlichen Gesetzbuches (BGB) oder Artikel 248 des Einführungsgesetzes zum Bürgerlichen
            Gesetzbuche (EGBGB) werden wir grundsätzlich spätestens innerhalb von 15 Arbeitstagen nach Eingang der
            Beschwerde beantworten.
          </p>
          <p>
            Können wir ausnahmsweise innerhalb der vorgenannten Fristen Ihre Beschwerde nicht beantworten, werden wir
            Sie hierüber, über die Gründe der Verzögerung und die voraussichtliche Bearbeitungsdauer informieren. Eine
            Beschwerde in Bezug auf Ihre vorgenannten Rechte und Pflichten als Zahlungsdienstnutzer oder Artikel 248
            EGBGB werden wir nicht später als 35 Arbeitstage nach Eingang der Beschwerde abschließend beantworten.
          </p>
          <h2>Wie endet das Beschwerdeverfahren?</h2>
          <p>
            Sie erhalten von uns eine Antwort, in der wir Ihnen das Ergebnis unserer Prüfung mitteilen. Können wir
            Ihrem
            Anliegen nicht vollständig nachkommen, erläutern wir Ihnen unseren Standpunkt.
          </p>
          <h2>Beschwerdemöglichkeiten bei Dritten.</h2>
          <p>
            Stellt Sie unsere Antwort nicht zufrieden, haben Sie alternativ zur Einreichung einer zivilrechtlichen
            Klage
            immer die Möglichkeit, Ihre Beschwerde aufrechtzuerhalten und sich an eine der nachfolgenden Stellen zu
            wenden.
          </p>
          <ul>
            <li>
              <strong>Ombudsmann der privaten Banken:</strong>
              <p>
                Die Bank nimmt am außergerichtlichen Streitbeilegungsverfahren der Verbraucherschlichtungsstelle
                "Ombudsmann der privaten Banken" (<a href="https://bankenombudsmann.de/" target="_blank">www.bankenombudsmann.de</a>)
                teil. Dort hat der Verbraucher die Möglichkeit, zur Beilegung einer Streitigkeit mit der Bank den
                Ombudsmann der privaten Banken anzurufen. Betrifft der Beschwerdegegenstand eine Streitigkeit über
                einen
                Zahlungsdienstevertrag (§ 675f BGB des Bürgerlichen Gesetzbuches), können auch Kunden, die keine
                Verbraucher sind, den Ombudsmann der privaten Banken anrufen. Näheres regelt die "Verfahrensordnung
                für
                die Schlichtung von Kundenbeschwerden im deutschen Bankgewerbe", die auf Wunsch zur Verfügung gestellt
                wird oder im Internet unter <a href="https://bankenverband.de/"
                                               target="_blank">www.bankenverband.de</a>
                abrufbar ist. Die Beschwerde ist in Textform (zum Beispiel mittels Brief, Telefax oder E-Mail) an die
                Kundenbeschwerdestelle Geschäftsstelle des Ombudsmann der privaten Banken beim Bundesverband deutscher
                Banken e. V., Postfach 04 03 07, 10062 Berlin, Fax: 030 1663-3169, E-Mail:
                <a href="mailto:ombudsmann@bdb.de">ombudsmann&#64;bdb.de</a>, zu richten.
              </p>
            </li>
            <li>
              <strong>Bundesanstalt für Finanzdienstleistungsaufsicht:</strong>
              <p>
                Für Kunden besteht die Möglichkeit, sich jederzeit schriftlich oder zur dortigen Niederschrift bei der
                Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin), Graurheindorfer Straße 108, 53117 Bonn, über
                Verstöße der Bank gegen das Zahlungsdiensteaufsichtsgesetz (ZAG), die §§ 675c bis 676c des
                Bürgerlichen
                Gesetzbuches (BGB) oder gegen Artikel 248 des Einführungsgesetzes zum Bürgerlichen Gesetzbuch (EGBGB)
                oder wegen der Kündigung eines Basiskontovertrages zu beschweren. Ferner kann ein Kunde wegen der
                Ablehnung seines Antrages auf Abschluss eines Basiskontovertrages nach § 48 des Zahlungskontengesetzes
                (ZKG) bei der BaFin die Durchführung eines Verwaltungsverfahrens beantragen.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</app-page-wrapper>