<div class="container">
  <div class="row mt-3 mt-md-5 text-center">
    <div class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
      <div class="mt-3 mt-md-5 text-center">
        <div class="logo" title="Logo {{brandName}}"></div>
        <div *ngIf="requestState.loading">
          <br>
          <hr aria-hidden="true">
          <br>
          <p class="text-uppercase">Volkswagen Bank GmbH</p>
          <small class="text-uppercase">The Key To Mobility</small>
        </div>
      </div>

      <div class="mt-3">
        <div class="card">
          <div class="card-body text-center">

            <div class="mt-5" *ngIf="requestState.loading">
              <img class="img-responsive rotate-center" src="assets/icons/spinner.svg" width="80"
                   aria-hidden="true" alt="">
              <h1 class="mt-5">Simulation purchase is loading ...</h1>
            </div>

            <div *ngIf="!requestState.loading && purchaseInfo">
              <h2>Simulation purchase</h2>
              <div class="row mt-2">
                <div class="col-12 col-sm-5 text-left">
                  <strong>Amount:</strong>
                </div>
                <div class="col-12 col-sm-7 text-right">
                  {{ purchaseInfo.amount | currencyPostfix }}
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-5 text-left">
                  <strong>Merchant:</strong>
                </div>
                <div class="col-12 col-sm-7 text-right">
                  {{ purchaseInfo.merchantName }}
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-5 text-left">
                  <strong>Type:</strong>
                </div>
                <div class="col-12 col-sm-7 text-right">
                  {{ purchaseInfo.pointOfSale ? 'POS' : 'E-Commerce' }}
                </div>
              </div>
              <div class="row mt-5"
                   *ngIf="!purchaseInfo.referralUrlUsed && !requestState.success && !requestState.error">
                <div class="col-12" [class.col-sm-6]="purchaseInfo.pointOfSale">
                  <button mat-raised-button color="accent" class="w-100" (click)="triggerCallback('SUCCESS')">
                    SUCCESS
                  </button>
                </div>
                <div class="col-12 col-sm-6" *ngIf="purchaseInfo.pointOfSale">
                  <button mat-raised-button color="basic" class="w-100"
                          (click)="triggerCallback('PENDING_FULFILLMENT')">
                    PICK UP
                  </button>
                </div>

                <div class="col-12 col-sm-6 py-1">
                  <button mat-raised-button color="warn" class="w-100" (click)="triggerCallback('TIMEOUT')">
                    TIMEOUT
                  </button>
                </div>
                <div class="col-12 col-sm-6 py-1">
                  <button mat-raised-button color="warn" class="w-100" (click)="triggerCallback('CANCELLED')">
                    CANCELLED
                  </button>
                </div>
              </div>
              <div class="row mt-5"
                   *ngIf="purchaseInfo.referralUrlUsed || (requestState.success && !requestState.error)">
                <div class="col-12 text-success text-center">
                  <h3><strong>Callback was triggered</strong></h3>
                </div>
                <div class="col-12 text-center" *ngIf="requestState.referenceId && !requestState.error">
                  <h3><strong>Pickup id: {{ requestState.referenceId }}</strong></h3>
                </div>
              </div>
            </div>
            <div class="mt-3" *ngIf="requestState.error">
              <img class="img-responsive c2-anim-loader" src="assets/icons/nok.svg" width="80" aria-hidden="true"
                   alt="">
              <div *ngIf="requestState.error.resultCode || requestState.error.invalidParams">
                <h2>Invalid URL</h2>
                <p>Parameters are invalid or missing.</p>
              </div>
              <div *ngIf="requestState.error.success === false">
                <h2>Error triggerering callback</h2>
                <p>{{ requestState.error.error }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
