import {Component} from '@angular/core';
import {environment} from '@env/environment';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.css'],
    standalone: false
})
export class TermsOfUseComponent {

  public readonly BRAND_NAME: string = environment.brandName;
  public readonly MAIN_URL: string = environment.mainUrl;
  public readonly MERCHANT_TERMS_URL: string = environment.merchantTermsUrl;

  public readonly DATA_PRIVACY_URL: string = this.MAIN_URL + '/#/data-privacy';

  constructor() { }

}
