<app-page-wrapper>
  <div class="row">
    <div class="col-12 mb-md-3">
      <h1>Impressum</h1>
    </div>
    <div class="col-12">
      <div itemscope="" itemtype="http://schema.org/Organization"><h2>Volkswagen Bank GmbH</h2>
        <div itemscope="" itemtype="http://schema.org/PostalAddress" itemprop="address"><span itemprop="streetAddress">Gifhorner Straße 57</span><br>
          <span itemprop="postalCode">38112</span>&nbsp;<span itemprop="addressLocality">Braunschweig</span></div>
        <strong>Telefon:&nbsp;</strong><span itemprop="telephone">+49 (0)531 212-02</span><br>
        <strong>Telefax:&nbsp;</strong><span itemprop="telephone">+49 (0)531 212-2275</span><br>
        <strong>E-Mail:&nbsp;</strong><span itemprop="email"><a
            href="mailto:info@volkswagenbank.de">info&#64;volkswagenbank.de</a></span><br>
        <br>
        <p>
          <strong>Geschäftsführung:&nbsp;</strong><br>
          <span itemscope="" itemtype="http://schema.org/Person"><span
              itemprop="name">Dr. Volker Stadler (Sprecher)</span></span>,&nbsp;
          <span itemscope="" itemtype="http://schema.org/Person"><span itemprop="name">Christian Löbke</span></span>,
          <span itemscope="" itemtype="http://schema.org/Person"><span itemprop="name">Oliver Roes</span></span>
        </p>
        <p>
          <span>Amtsgericht Braunschweig HRB 1819</span><br>
          <span><strong>Umsatzsteuer-IdNr.:</strong> DE 811.115.569</span>
        </p>
        <p>
          <strong>Aufsichtsbehörden:&nbsp;</strong><br>
          <span itemscope="" itemtype="http://schema.org/Organization"><span>Europäische Zentralbank</span></span><br>
          <span itemscope="" itemtype="http://schema.org/PostalAddress" itemprop="address"><span
              itemprop="streetAddress">Sonnemannstraße 20</span><br>
            <span itemprop="postalCode">60314</span>&nbsp;<span
                itemprop="addressLocality">Frankfurt am Main</span></span>
        </p>
        <p>
          <span itemscope="" itemtype="http://schema.org/Organization"><span>Bundesanstalt für Finanzdienstleistungsaufsicht</span></span><br>
          <span itemscope="" itemtype="http://schema.org/PostalAddress" itemprop="address"><span
              itemprop="streetAddress">Graurheindorfer Straße 108</span><br>
            <span itemprop="postalCode">53117</span>&nbsp;<span itemprop="addressLocality">Bonn</span></span><br>
          <span>und</span><br>
          <span itemscope="" itemtype="http://schema.org/PostalAddress" itemprop="address"><span
              itemprop="streetAddress">Marie-Curie-Straße 24-28</span><br>
            <span itemprop="postalCode">60439</span>&nbsp;<span
                itemprop="addressLocality">Frankfurt am Main</span></span>
        </p>
        <p>
          <span>Erlaubnis nach § 34 d I GewO (Versicherungsvertreter), erteilt durch die</span>
        </p>
        <p>
          <span itemscope="" itemtype="http://schema.org/Organization"><span>IHK Braunschweig</span></span><br>
          <span itemscope="" itemtype="http://schema.org/PostalAddress" itemprop="address"><span
              itemprop="streetAddress">Brabandtstraße 11</span><br>
            <span itemprop="postalCode">38100</span>&nbsp;<span itemprop="addressLocality">Braunschweig</span></span>
        </p>
        <p>
          <span>Versicherungsvermittlerregister</span><br>
          <span><strong>Registernr.: </strong><span>D-HNQM-UQ9MO-22</span></span><br>
          <span itemprop="website"><a target="_blank" href="https://www.vermittlerregister.info">www.vermittlerregister.info</a></span>
        </p>
      </div>
    </div>
  </div>
</app-page-wrapper>