import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-data-privacy',
    templateUrl: './data-privacy.component.html',
    standalone: false
})
export class DataPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
