import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-data-privacy-homepage-fab',
    templateUrl: './data-privacy-homepage-fab.component.html',
    styleUrls: ['./data-privacy-homepage-fab.component.css'],
    standalone: false
})
export class DataPrivacyHomepageFabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
