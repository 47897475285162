<main class="pb-0 pb-sm-5">
  <div class="c2-overlay">
    <div class="c2-wizard-container mx-auto p-3">
      <div class="text-center spinner" *ngIf="loading">
        <div class="spinner-container">
          <img src="assets/icons/spinner.svg" class="rotate-center" width="70" aria-hidden="true" alt="">
          <div class="mt-5">
            <p><strong>Wir rechnen gerade,<br> bitte haben Sie einen Moment Geduld.</strong></p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!loading">
        <div class="col-12 text-center" *ngIf="!inputToggled">
          <h1>Wunschbetrag eingeben</h1>
          <div class="col-12 text-center calc__chevron--spacing">
            <a (click)="inputToggled = !inputToggled">
              <span class="material-icons calc__chevron--size">expand_more</span>
            </a>
          </div>
          <div class="col-12 text-center my-0 calc__desired-amount">
            <a id="calc-desired-amount-link" (click)="inputToggled = !inputToggled">
              {{ amount | currencyPostfix }}
            </a>
            <hr aria-hidden="true" class="text-center calc-desired-amount-underline"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8 offset-2 mt-1" *ngIf="inputToggled">
          <form [formGroup]="amountForm" (ngSubmit)="submit();inputToggled = !inputToggled">
            <mat-form-field appearance="outline" class="w-100 btn-suffix">
              <mat-label>EUR</mat-label>
              <input matInput type="number" placeholder="EUR" focus max="{{terms.limit}}"
                     formControlName="amountControl"
                     min="{{terms.minPurchaseAmount}}" class="calc__desired-amount">
              <button matSuffix mat-raised-button color="accent" type="submit" [disabled]="!amountForm.valid"
                      class="calc__desired-amount-button">
                OK
              </button>
              <mat-hint *ngIf="terms?.limit">
                Max. {{ terms.limit| currencyPostfix }}
              </mat-hint>
            </mat-form-field>
          </form>
        </div>
      </div>
      <app-show-plans [terms]="terms" [annuities]="annuities" *ngIf="!loading" [tmpNewDesign]="true"></app-show-plans>
    </div>
  </div>
</main>