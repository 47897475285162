import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-tac',
    templateUrl: './tac.component.html',
    standalone: false
})
export class TacComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
