import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
    selector: 'app-data-privacy-homepage',
    templateUrl: './data-privacy-homepage.component.html',
    styleUrls: ['./data-privacy-homepage.component.css'],
    standalone: false
})
export class DataPrivacyHomepageComponent implements OnInit {

  public readonly BRAND_NAME = environment.brandName;

  constructor() { }

  ngOnInit() {
  }

}
