import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-complaint-handling',
    templateUrl: './complaint-handling.component.html',
    standalone: false
})
export class ComplaintHandlingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
