<app-page-wrapper>
  <div class="row">
    <div class="col-12 col-md-8 offset-md-2 mt-5">
      <div class="card shadow-sm">
        <div class="card-body">
          <app-data-privacy-homepage-sk *ngIf="BRAND_NAME === 'Sofortkredit'"></app-data-privacy-homepage-sk>
          <app-data-privacy-homepage-fab *ngIf="BRAND_NAME === 'FINANCE A BIKE'"></app-data-privacy-homepage-fab>
        </div>
      </div>
    </div>
  </div>
</app-page-wrapper>